import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import smoothscroll from 'smoothscroll-polyfill'
import * as firebase from 'firebase/app'
import 'firebase/analytics'
import App from './App'
import reportWebVitals from './reportWebVitals'
import Config from './config'

// kick off the polyfill!
smoothscroll.polyfill()

// Initialize Firebase
const app = firebase.initializeApp(Config.firebaseConfig)
firebase.analytics(app)

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
