import { Config } from './types'

const config: Config = {
  ENV: 'local',
  BASE_URL: 'http://console-develop.moodii.io',
  auth: {
    RESTFUL_URL: 'http://192.168.100.143:8999/auth'
  },
  message: {
    RESTFUL_URL: 'http://192.168.100.143:12002'
  },
  user: {
    RESTFUL_URL: 'http://192.168.100.134:8999'
  },
  activity: {
    RESTFUL_URL: 'http://192.168.100.134:8999/activity'
  },
  firebaseConfig: {
    apiKey: 'AIzaSyDrXlnC2NEQzho788RRdwz25CB2e5AtM18',
    authDomain: 'moodii-app.firebaseapp.com',
    databaseURL: 'https://moodii-app.firebaseio.com',
    projectId: 'moodii-app',
    storageBucket: 'moodii-app.appspot.com',
    messagingSenderId: '755350716309',
    appId: '1:755350716309:web:3f69becdecb8d857c3409c',
    measurementId: 'G-LCZ7JK0HM8'
  },
  previewLink: 'https://share.app-staging.moodii.io'
}

export default config
