import React, { useEffect } from 'react'

export default function Home(): JSX.Element {
  useEffect(() => {
    // 獲取終端的相關資訊
    let Terminal = {
      // 辨別移動終端型別
      platform: (function p() {
        let u = navigator.userAgent
        return {
          // android終端或者uc瀏覽器
          android: u.indexOf('Android') > -1 || u.indexOf('Linux') > -1,
          // 是否為iPhone或者QQHD瀏覽器
          iPhone: u.indexOf('iPhone') > -1,
          // 是否iPad
          iPad: u.indexOf('iPad') > -1,
          // 是否為windows
          windows: u.indexOf('Windows NT') > -1,
          // 是否為mac
          mac: u.indexOf('Mac')
        }
      })(),
      // 辨別移動終端的語言：zh-cn、en-us、ko-kr、ja-jp...
      language: navigator.language.toLowerCase()
    }

    // 根據不同的終端，跳轉到不同的地址
    if (Terminal.platform.android) {
      location.href =
        'https://play.google.com/store/apps/details?id=space.zoomo.moodii'
    } else if (Terminal.platform.iPhone) {
      location.href = 'https://apps.apple.com/tw/app/id1521782760'
    } else {
      location.href = 'https://www.moodii.io'
    }
  }, [])

  return <div />
}
