import React from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import Home from './page/home'

export default function App(): JSX.Element {
  return (
    <Router>
      <Switch>
        <Route exact path="/*">
          <Home />
        </Route>
      </Switch>
    </Router>
  )
}
