import base from './base'
import { Config } from './types'

const config: Config = {
  ...base,
  ENV: 'staging',
  BASE_URL: 'https://console-staging.moodii.io',
  auth: {
    ...base.auth,
    RESTFUL_URL: 'https://auth.api-staging.moodii.io/restful/v1'
  },
  message: {
    ...base.message,
    RESTFUL_URL: 'https://message.api-staging.moodii.io/restful/v1'
  },
  user: {
    ...base.user,
    RESTFUL_URL: 'https://user.api-staging.moodii.io/restful/v1'
  },
  activity: {
    ...base.user,
    RESTFUL_URL: 'https://activity.api-staging.moodii.io/restful/v1'
  }
}

export default config
