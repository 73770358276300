import base from './base'
import local from './local'
import develop from './develop'
import staging from './staging'
import production from './production'
import { Config } from './types'

function getConfig(): Config {
  if (process.env.REACT_APP_ENV === 'local') return local
  if (process.env.REACT_APP_ENV === 'develop') return develop
  if (process.env.REACT_APP_ENV === 'staging') return staging
  if (process.env.REACT_APP_ENV === 'production') return production
  return base
}

const config: Config = getConfig()

export default config
