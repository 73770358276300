import base from './base'
import { Config } from './types'

const config: Config = {
  ...base,
  ENV: 'develop',
  auth: {
    ...base.auth,
    RESTFUL_URL: 'http://auth.api-develop.moodii.io/restful/v1'
  },
  message: {
    ...base.message,
    RESTFUL_URL: 'http://message.api-develop.moodii.io/restful/v1'
  },
  user: {
    ...base.user,
    RESTFUL_URL: 'http://user.api-develop.moodii.io/restful/v1'
  },
  activity: {
    ...base.user,
    RESTFUL_URL: 'http://activity.api-develop.moodii.io/restful/v1'
  }
}

export default config
