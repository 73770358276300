import base from './base'
import { Config } from './types'

const config: Config = {
  ...base,
  ENV: 'production',
  BASE_URL: 'https://console.moodii.io',
  auth: {
    ...base.auth,
    RESTFUL_URL: 'https://auth.api.moodii.io/restful/v1'
  },
  message: {
    ...base.message,
    RESTFUL_URL: 'https://message.api.moodii.io/restful/v1'
  },
  user: {
    ...base.user,
    RESTFUL_URL: 'https://user.api.moodii.io/restful/v1'
  },
  activity: {
    ...base.user,
    RESTFUL_URL: 'https://activity.api.moodii.io/restful/v1'
  },
  previewLink: 'https://share.app.moodii.io'
}

export default config
