import base from './base'
import { Config } from './types'

const config: Config = {
  ...base,
  ENV: 'local',
  auth: {
    ...base.auth,
    RESTFUL_URL: 'http://192.168.100.134:8080/restful/v1'
  },
  message: {
    ...base.message,
    RESTFUL_URL: 'http://192.168.100.134:8081/restful/v1'
  },
  user: {
    ...base.user,
    RESTFUL_URL: 'http://192.168.100.134:8082/restful/v1'
  },
  activity: {
    ...base.user,
    RESTFUL_URL: 'http://192.168.100.134:8083/restful/v1'
  }
}

export default config
